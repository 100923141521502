import React from 'react';

import './ConnectorStatus.css';


interface Props{
    status: string;
}

const ConnectorStatus = ({status}:Props) => {
    return(
        <div className='ConnectorStatus'>
            {status} 
        </div>
    )
}

export default ConnectorStatus;
