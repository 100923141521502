import React from 'react';

import './CardHome.css';
import ConnectorStatus from '../ConnectorStatus/ConnectorStatus';

interface Props{
    image: string;
    type: string;
    name: string;
    status: string;
    isSelect?: boolean;
}

const CardHome = ({image,type,name, status, isSelect}:Props) => {
    return(
        <div className={"CardHome-container " + (isSelect ? "CardHome-container-Selected" : "")}>
            <div className="CardHome-image">
                <img src={image} alt={name} />
            </div>
            <div className="CardHome-info">
                <span>{type}</span>
                <span>{name}</span>
            </div>
            <ConnectorStatus status={status} ></ConnectorStatus>
        </div>

    )
}

export default CardHome;
