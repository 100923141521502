import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './Home.css';
import TitleText from 'src/components/TitleText/TitleText';
import SubText from 'src/components/SubText/SubText';
import Notification from 'src/icons/Notification';
import SubTitle from 'src/components/SubTitle/SubTitle';
import CardHome from 'src/components/CardHome/CardHome';
import Slider from 'src/components/Slider/Slider';
import { PromisePageContext } from 'src/machines/pageMachine';
import { useNavigate, useLocation } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { chargerId } = location.state || {};
  let chargerSerialId = chargerId; //available directly from chargerId
  let connectorId = 1;//set to 1 right now as all our chargers have 1 connector, will be changed later

  const cardsContainerRef = useRef<HTMLDivElement | null>(null);
  //const [isElementInCenter, setIsElementInCenter] = useState(false);
    const { page, pageSend } = useContext(PromisePageContext);
    const [active,setActive] = useState<boolean>(false);
    const [selectedElement, setSelectedElement] = useState<number>(0);

    const [timeElapsed, setTimeElapsed] = useState<number>(0); // Time in seconds
    
    const [charger, setCharger] = useState<any[]>([
      { 
        id: 1, 
        name: "My Charger", 
        type: "1.5 CAD / hr", 
        status: "Available",
        image: chargerId.toLowerCase().includes('tel') ? "/image/teltonika.jpg" : 
               chargerId.toLowerCase().includes('fr') ? "/image/fractal2.png" :
               chargerId.toLowerCase().includes('ev') ? "/image/evduty.png" :
               "/image/teltonika.jpg"  // fallback image if none of the above matches
      },
    ]);
    
    let name = 'EV Driver';

    const styles = {
      backgroundColor: page.context.darkMode ? "#141D2F" : "#FFFFFF",
      color: page.context.darkMode ? "#FFFFFF" : "#000000",
    }

    useEffect(() => {
      let intervalId: NodeJS.Timeout;
  
      if (active) {
        const startTime = Date.now();
  
        // Update the elapsed time every second
        intervalId = setInterval(() => {
          const currentTime = Date.now();
          setTimeElapsed(Math.floor((currentTime - startTime) / 1000)); // Time in seconds
        }, 1000);
      }
  
      // Cleanup the interval when the component is unmounted or stopped
      return () => clearInterval(intervalId);
    }, [active]);

  
    // Format time as hours, minutes, and seconds
    const formatTime = (seconds: number): string => {
      const hours = Math.floor(seconds / 3600);
      const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
    
      return `${hours}:${minutes}`;
    };
    

    useEffect(() => {

      }, [selectedElement]);

       useEffect(() => {
        const handleScroll = () => {
          if (cardsContainerRef.current) {

            const container = cardsContainerRef.current;
            const containerWidth = container.offsetWidth;
            const scrollLeft = container.scrollLeft;
            const centerPosition = containerWidth / 2;
            const cards = container.getElementsByClassName('CardHome-container') as HTMLCollectionOf<HTMLElement>;
            let elementInCenterIndex = null;

            for (let index = 0; index < cards.length; index++) {
              const card = cards[index];
              const cardLeft = card.offsetLeft;
              const cardWidth = card.offsetWidth;

              if (cardLeft <= centerPosition + scrollLeft && cardLeft + cardWidth >= centerPosition + scrollLeft) {
                elementInCenterIndex = index;
                break;
              }
            }

            setSelectedElement(elementInCenterIndex || 0);
          }
        };
        const containerRef = cardsContainerRef.current;
        if (containerRef) {
          containerRef.addEventListener('scroll', handleScroll);
        }
    
        return () => {
          if (containerRef) {
            containerRef.removeEventListener('scroll', handleScroll);
          }
        };
      }, []);
    
    let promoCode:any;
    
	const startCharging = () => {
		fetch(`https://api.jplug.io/set-send-startDate/${chargerSerialId}`, {
			method: "POST",
			headers: { "Content-Type": "application/json" }
		}).then(response => {
			if (!response.ok) {
			}
		}).then(data => { })
			.catch(error => {
				navigate('/404', { state: { chargerId: chargerSerialId } });
			});
	}
	const stopCharging = () => {
		fetch(`https://api.jplug.io/api/remoteStopTransaction/${chargerSerialId}`, {
			method: "POST",
			headers: { "Content-Type": "application/json" }
		}).then(response => {
			  getPromoCode();
			})
			.catch(error => {
				navigate('/404', { state: { chargerId: chargerSerialId } });
			});
	}
	const getPromoCode=()=>{
			promoCode=page.context.invoice.promoCode;
			calculateAndMakeTransaction();
	}

	const calculateAndMakeTransaction = () => {
		fetch(`https://api.jplug.io/chargers/${chargerSerialId}/connectors/${connectorId}/session-summary`, {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		})
			.then(response => response.json())
			.then(response => {
				let time = response.hours;
        let date = response.stopDate;
        let energy = response.energy;

				let charges = response.charges;
        let amountBeforeTaxes = charges.amountBeforeTaxes;
        let taxes = charges.taxes;
        let totalAfterTaxes = charges.totalAfterTaxes;
        let transactionCharges = charges.transactionCharges;
        let amountToCharge = charges.amountToCharge;

				if(promoCode===''){
          pageSend({ type: "CHANGE_INVOICE", data: { invoice: {
            time: time,
            date: date,
            energy: energy,
            amountBeforeTaxes: amountBeforeTaxes,
            taxes: taxes,
            totalAfterTaxes: totalAfterTaxes,
            transactionCharges: transactionCharges,
            amountToCharge: amountToCharge
          }
         }});
				  doTransaction(amountToCharge);
				}
				if(promoCode){
					pageSend({ type: "CHANGE_INVOICE", data: { invoice:{
            time: time,
            date: date,
            energy: energy,
            amountBeforeTaxes: 0.00,
            taxes: 0.00,
            totalAfterTaxes: 0.00,
            transactionCharges: 0.00,
            amountToCharge: 0.00
          } }});
				}
				navigate("/MoneyReceived", { state: { chargerId: chargerSerialId } });
			})
			.catch(error => {
				navigate('/404', { state: { chargerId: chargerSerialId } });
			});
	}
  // getChargingState makes an api call to API service to fetch the current charger status.
  // The function has been memoized with useCallback() so that it only changes when the 
  // chargerSerialId or connectorId changes.
  const getChargingState = useCallback(() => {
    fetch(`https://api.jplug.io/chargers/${chargerSerialId}/connectors/${connectorId}/status`, {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		})
    .then(response => {
      if (!response.ok) {
          // If the response is not OK, update the status to "Unavailable"
          setCharger(currentCharger => 
              currentCharger.map(chg => ({ ...chg, status: "Status Unavailable" }))
          );
          throw new Error('Network response was not ok');
      }
      return response.json(); // If OK, return the text
  })
      .then(response =>

        setCharger(currentCharger => currentCharger.map(chg => ({ ...chg, status: response.status})))
      )
      .catch(console.error)
  },[chargerSerialId, connectorId]);

  useEffect(() => {
    if (chargerSerialId && connectorId) { // Ensure the API call only happens if chargerSerialId and connectorId are available
      getChargingState(); 
      // Set up polling to update status every 10 seconds
        const intervalId = setInterval(() => {
          getChargingState();
      }, 10000); // Fetch connector status every 10 seconds. TO DO: We will need to add an algorithm 
                // which will fetch data depending on the connector status.

      // Clear the interval on component unmount
      return () => clearInterval(intervalId);
    }
  },[chargerSerialId, connectorId, getChargingState]);
  

	const doTransaction = (totalAmount: any) => {
    
		fetch(`https://api.jplug.io/do-transaction/${chargerSerialId}?` + new URLSearchParams({ totalAmount: totalAmount }), {
			method: "POST",
			headers: { "Content-Type": "application/json" },
		})
			.catch(error => {
				navigate('/404', { state: { chargerId: chargerSerialId } });
			});
	}

  

    return(
        <div className="Home-layout" style={styles}>
            <div className="Homeprofile-notification">

                <div className="Homeprofile">
                    <SubText text="Hello 👋"></SubText>
                    <TitleText text={name}></TitleText>
                </div>

                <div className="Homenotification">
                    <Notification></Notification>
                </div>
            </div>

            <SubTitle text="Charger" url="/Chargers"></SubTitle>

            <div className='Home-Cards' ref={cardsContainerRef}>
                {charger.map((item,index)=>(
                    <CardHome status={item.status} image={item.image} type={item.type} name ={item.name} isSelect={index === selectedElement}></CardHome>
                ))}
            </div>

            {active && (
              <p>Charging Session Duration (H:M): {formatTime(timeElapsed)}</p>
            )}

            <Slider active={active} setActive={setActive} activeActive={startCharging} inactiveExecute={stopCharging}></Slider>
            { /* <SecundaryButton text="Add Charger" style={{margin:"20px 0"}} icon={<Plus/>}></SecundaryButton> */}
        </div>
    )
}

export default Home;
