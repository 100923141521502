import React, { useContext } from 'react';
import './MoneyReceived.css';

import TitleText from 'src/components/TitleText/TitleText';
import HeaderBack from 'src/components/HeaderBack/HeaderBack';
import DataList from 'src/components/DataList/DataList';
import { PromisePageContext } from 'src/machines/pageMachine';
import { useLocation } from 'react-router-dom';

const MoneyReceived = () => {
    const { page } = useContext(PromisePageContext);

    //Retrieve invoice data
    let stopDate = page.context.invoice.date;
    let energyConsumed = page.context.invoice.energy;
    let time = page.context.invoice.time;
    let taxes = page.context.invoice.taxes;
    let amountBeforeTaxes = page.context.invoice.amountBeforeTaxes;
    let totalAfterTaxes = page.context.invoice.totalAfterTaxes;
    let transactionCharges = page.context.invoice.transactionCharges;
    let amountToCharge = page.context.invoice.amountToCharge;

    const location = useLocation();
    const { chargerId } = location.state || {};
    let chargerSerialId = chargerId.chargerId; //extract from property

    let session = [{title:'Time',value:time, type:'(HH:MM)',bold: false},{title:'Rate',value:1.50, type:'CAD / hr',bold: false},{title:'Total Before Taxes',value:amountBeforeTaxes,type:'CAD', bold: false}]
    let tax = [{title:'HST',value:taxes, type:'CAD',bold: false},{title:'Total After Taxes',value:totalAfterTaxes, type:'CAD',bold: true}]
    let stripeCharges = [{title:'Transaction Charges',value:transactionCharges, type:'CAD',bold: false},{title:'Final Amount Charged (CAD)',value:amountToCharge, type:'CAD',bold:true}];
    let energy = [{title:'Energy (Kwh)',value:energyConsumed, type:'Kwh',bold: false}]
    let date = [{title:'Date (DD-MM-YYYY)',value:stopDate, type:'',bold: false}]

    const urlBack = `/${chargerSerialId}`;

    const styles = {
        backgroundColor: page.context.darkMode ? "#141D2F" : "#FFFFFF",
        color: page.context.darkMode ? "#FFFFFF" : "#000000",
      }

    return(
        <div className='MoneyReceived-layout' style={styles}>
            {/*<label>{page.context.invoice?.summary} {page.context.invoice?.time}</label>*/}
            <HeaderBack urlBack={urlBack}  title='Money Received'></HeaderBack>
            <TitleText text='Thank you for charging with us' styles={{marginBottom:"28px"}}></TitleText>
            <DataList label='Session Summary' dataoptions={session}></DataList>
            <DataList label='Taxes' dataoptions={tax}></DataList>
            <DataList label='Transaction Charges' dataoptions={stripeCharges}></DataList>
            <DataList label='Energy Added' dataoptions={energy}></DataList>
            <DataList label='Date' dataoptions={date} styles={{marginBottom:"75px"}}></DataList>
        </div>
    )
}
export default MoneyReceived
